import React, {useState} from "react";
import "./PaymentMethods.css";
import masterCardIcon from "../../images/payment-methods/mastercard.png"
import visaIcon from "../../images/payment-methods/visa.png"
import paypalIcon from "../../images/payment-methods/paypal.png"
import ethIcon from "../../images/payment-methods/eth.png"
import btcIcon from "../../images/payment-methods/btc.png"
import usdtIcon from "../../images/payment-methods/usdt.png"
import {useHistory} from "react-router-dom";
import {Backdrop, CircularProgress} from "@mui/material";
import {notification} from "../Notification";
import {client} from "../../services/client";

export const PaymentMethods = ({pack = null, lot = null, terms = true}) => {
  const history = useHistory();
  const [backdropOpen, setBackdropOpen] = useState(false);

  const paymentMethods = [
    {"code": "CARD", "name": "mastercard", "icon": masterCardIcon},
    {"code": "CARD", "name": "visa", "icon": visaIcon},
    {"code": "PAYPAL", "name": "paypal", "icon": paypalIcon},
    {"code": "BTC", "name": "btc", "icon": btcIcon},
    {"code": "ETH", "name": "eth", "icon": ethIcon},
    {"code": "USDT", "name": "usdt", "icon": usdtIcon},
  ];

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };
  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const createOrder = async (e) => {
    if (e.target.id === "CARD") {
      notification.warning("Payment by card is not available now");
      return;
    }
    if (!terms) {
      notification.warning("You must approve terms");
      return;
    }
    handleBackdropOpen();
    try {
      const response = await client.createOrder({
        productId: pack ? pack.id : lot.id,
        productType: pack ? "BID_PACK" : "LOT",
        paymentMethod: e.target.id
      });
      if (e.target.id === "PAYPAL") {
        window.location.replace(response.paypalRedirectUrl, "_blank");
      } else {
        history.push("/orders/status?id=" + response.externalId);
      }
    } catch (e) {
      notification.warning(e.message)
    }
    handleBackdropClose();
  }

  return (
    <div className="payment-methods-container">
      <div className="caption">Choose payment method</div>
      <div className="payment-methods-inner">
        {paymentMethods && paymentMethods.map((e, i) => {
          return <div id={e.code} className="payment-method" onClick={createOrder}>
            <img id={e.code} src={e.icon} alt={e.name}/>
          </div>
        })}
      </div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}