import React, {useCallback, useEffect, useState} from "react";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {BidPack} from "../../components/BidPack/BidPack";
import "./Orders.css";
import {useHistory} from "react-router-dom";
import {Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {toEur} from "../../utils/tools";
import {PaymentMethods} from "../../components/PaymentMethods/PaymentMethods";

export const LotsOrder = () => {
  const history = useHistory();
  const [termsApproved, setTermsApproved] = useState(false);
  const [lot, setLot] = useState();

  let id = new URLSearchParams(history.location.search).get('id');

  const [headerFields, setHeaderFields] = useState([
    {name: 'item', value: 'Item', direction: 'asc', sorted: true},
    {name: 'empty', value: '', direction: 'asc', sorted: true},
    {name: 'price', value: 'Final price', direction: 'asc', sorted: true},
  ]);

  const getLot = useCallback(async () => {
    try {
       const response = await client.getWinLotById(id);
       setLot(response);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [lot]);

  useEffect(() => {
    getLot()
  }, []);


  const handleChangeTermsApprove = () => {
    setTermsApproved(!termsApproved);
  }

  return (
    <div className="content-relative">
      <div className="orders-pack-container">
        <div className="caption">Won Auction</div>
        <div>
          Here you see the price and delivery of the item. You may cancel the order. Read more in our FAQs. Choose your
          payment methods below.
        </div>
      </div>

      <div className="box">
        <div className="caption">Won auction</div>
        <div>
          <Table className="table" size="small">
            <TableHead>
              <TableRow>
                {headerFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {lot &&
              <>
                <TableRow hover>
                  <TableCell style={{width: "60%"}}>{lot.shortDescription}</TableCell>
                  <TableCell style={{width: "20%"}}/>
                  <TableCell style={{width: "20%"}}>{toEur(lot.currentAmount)}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell style={{width: "60%"}}>Delivery</TableCell>
                  <TableCell style={{width: "20%"}}/>
                  <TableCell style={{width: "20%"}}>{toEur(lot.deliveryPrice)}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell style={{width: "60%"}}/>
                  <TableCell style={{width: "20%", fontWeight: 600}}>Total to pay</TableCell>
                  <TableCell style={{width: "20%", fontWeight: 600}}>{toEur(lot.currentAmount + lot.deliveryPrice)}</TableCell>
                </TableRow>
              </>
              }
            </TableBody>
          </Table>
        </div>

        <div className="order-terms-container">
          <FormControlLabel control={<Checkbox checked={termsApproved} onChange={handleChangeTermsApprove}/>}
                            label="I approve the terms and conditions for this purchase"/>
        </div>
      </div>

      <PaymentMethods lot={lot} terms={termsApproved}/>

    </div>
  )
}