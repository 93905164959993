import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const GreenButton = withStyles(() => ({
  root: {
    textTransform: "none !important",
    color: "#FFFFFF !important",
    background: "linear-gradient(180deg, #019321 0%, rgba(1, 147, 33, 0.49) 100%)",
    '&:hover': {
      background: "linear-gradient(180deg, #019321 10%, rgba(1, 147, 33, 0.49) 90%)",
    },
    borderRadius: "25px !important",
    fontSize: 18,
    width: 230,
    height: 50,
    boxSizing: "border-box"
  },
}))(Button);