import React from "react";
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {SmallOrangeButton} from "../BtbButton/SmallOrangeButton";
import {LoginButton} from "../BtbButton/LoginButton";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useDispatch, useSelector} from "react-redux";
import endingNowIcon from "../../images/ending-now-icon.png";
import {Badge, Tooltip} from "@mui/material";
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 8,
    backgroundColor: "#D92222"
  },
}));

export const Header = () => {
  const history = useHistory();
  const categories = useSelector(store => store.base.categories);
  const user = useSelector(store => store.base.user);

  const handleCategoryClick = (e) => {
    history.push("lots?categoryId=" + e.target.id);
  }

  return (
    <div className="header">
      <div className="top-bar">
        <div className="top-bar-inner">
          <div>
            <div className="top-bar-item"><MailOutlineIcon/>
              <a href="https://bitethebrit.freshdesk.com/" target="_blank">Customer support</a>
            </div>
            <div className="top-bar-item"><PersonAddIcon/><Link to={"/account/invite"}>Refer and Earn FREE bids</Link>
            </div>
          </div>
          {!user ?
            <div>
              <SmallOrangeButton onClick={() => history.push("/signup")}>Register</SmallOrangeButton>
              <LoginButton onClick={() => history.push("/login")}>Login</LoginButton>
            </div>
            :
            <div className="top-bar-icons-container">
              <div>
                <Tooltip
                  title="Cart"
                  onClick={() => history.push("/account/win")}
                >
                  <StyledBadge badgeContent={user.cartItems} color="secondary">
                    <ShoppingBagIcon fontSize="large"/>
                  </StyledBadge>
                </Tooltip>
              </div>
              <div><Tooltip title="Account" onClick={() => history.push("/account/dashboard")}><AccountCircleIcon
                fontSize="large"/></Tooltip></div>
            </div>
          }
        </div>
      </div>
      <div className="main-menu-container">
        <div className="logo" onClick={() => history.push("/")}/>
        <ul className="main-menu">
          <li>
            <div className="main-menu-dropdown">
              <span className="cursor-pointer">Categories</span>
              <div className="main-menu-dropdown-content">
                {categories && categories.map((c, i) => {
                  return <div id={c.id} className="main-menu-dropdown-content-item" onClick={handleCategoryClick}
                              key={i}>
                    <img alt={c.name} src={c.icon}/>
                    {c.name}
                  </div>
                })
                }
                <div id="ending" className="main-menu-dropdown-content-item" onClick={handleCategoryClick}>
                  <img alt="ending now" src={endingNowIcon}/>
                  ENDING NOW
                </div>
              </div>
            </div>
          </li>
          <li><Link to="/lots?categoryId=ending">Ending Now</Link></li>
          <li><Link to="/orders/bids/packs">Buy Bids</Link></li>
          <li><Link to="/faq">FAQ</Link></li>
        </ul>
      </div>
    </div>
  )
}