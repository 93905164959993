import React, {useCallback, useEffect, useState} from "react";
import {AccountMenu} from "../../components/AccountMenu/AccountMenu";
import "./Account.css";
import {isMobile} from "../../utils/checkDevice";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {BidPack} from "../../components/BidPack/BidPack";
import {Share} from "../../components/Share/Share";
import {BidBox} from "../../components/BidBox/BidBox";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {toEur} from "../../utils/tools";
import {useHistory} from "react-router-dom";

export const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);
  const [orders, setOrders] = useState();
  const [bidPacks, setBidPacks] = useState();
  const history = useHistory();

  const [headerFields, setHeaderFields] = useState(!isMobile() ? [
    {name: 'item', value: 'Item', direction: 'asc', sorted: true},
    {name: 'totalBids', value: 'Total bids', direction: 'asc', sorted: true},
    {name: 'totalOrder', value: 'Total order', direction: 'asc', sorted: true},
    {name: 'payment', value: 'Payment', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
  ] : [
    {name: 'item', value: 'Item', direction: 'asc', sorted: true},
    {name: 'totalOrder', value: 'Total order', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
  ]);


  const getBidPacks = useCallback(async () => {
    try {
      const response = await client.getBidPacks();
      setBidPacks(response);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [bidPacks]);

  useEffect(() => {
    getBidPacks()
  }, []);

  const getOrders = useCallback(async () => {
    try {
      const response = await client.getAllOrders({
        page: currentPage,
        size: 10,
      });
      setOrders(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage, orders]);

  useEffect(() => {
    getOrders();
  }, [currentPage])

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }



  return (
    <div className="content-relative">
      <div className="account-container">
        {!isMobile() && <AccountMenu tab={"dashboard"}/>}
        <div>
          <BidBox title="Dashboard"/>
          <div className="dashboard-box">
            {!isMobile() && <div className="caption">Buy Bids</div>}
            <div className="dashboard-pack-container">
              {bidPacks ? bidPacks.map((pack, i) => {
                return <BidPack pack={pack} redirect={true}/>
              }) : <BidPack/>}
            </div>
          </div>

          <div className="dashboard-box">
            <div className="caption">Your orders</div>
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  {headerFields.map(f =>
                    <TableCell key={f.name}>{f.value}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders && orders.map((e, index) => (
                  <TableRow hover key={index} onClick={() => history.push("/orders/status?id=" + e.externalId)}>
                    <TableCell>{e.name}</TableCell>
                    {!isMobile() && <TableCell>{e.bids}</TableCell>}
                    <TableCell>{toEur(e.amount)}</TableCell>
                    {!isMobile() && <TableCell>{e.paymentMethod}</TableCell>}
                    <TableCell>{e.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={total}
              rowsPerPage={10}
              rowsPerPageOptions={10}
              page={currentPage}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={onChangePage}
              className="table-pagination"
            />
          </div>

          <Share/>

        </div>
      </div>

    </div>
  )
}