import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const SmallOrangeButton = withStyles(() => ({
  root: {
    color: "white",
    background: "linear-gradient(359.48deg, #FF8359 0.44%, #FFDF40 99.56%)",
    '&:hover': {
      background: "linear-gradient(359.48deg, #FF8359 0.44%, #FFDF40 79.56%)",
    },
    borderRadius: 16,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    height: 34
  },
}))(Button);