import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const SmallOrangeTransparentButton = withStyles(() => ({
  root: {
    textTransform: "none !important",
    color: "#FF7800 !important",
    border: "1px solid #FF7800 !important",
    '&:hover': {
      border: "1px solid #FF7800 !important",
    },
    borderRadius: 16,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    height: 25
  },
}))(Button);