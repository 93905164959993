import React, {useCallback, useEffect, useState} from "react";
import {AccountMenu} from "../../components/AccountMenu/AccountMenu";
import {isMobile} from "../../utils/checkDevice";
import {useHistory} from "react-router-dom";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {toEur} from "../../utils/tools";
import {SmallOrangeButton} from "../../components/BtbButton/SmallOrangeButton";
import {SmallOrangeTransparentButton} from "../../components/BtbButton/SmallOrangeTransparentButton";

export const WinningBids = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);
  const [bids, setBids] = useState();
  const history = useHistory();

  const [headerFields, setHeaderFields] = useState(!isMobile() ? [
    {name: 'item', value: 'Won auction', direction: 'asc', sorted: true},
    {name: 'totalBids', value: 'You spent', direction: 'asc', sorted: true},
    {name: 'valueBids', value: 'Value bids', direction: 'asc', sorted: true},
    {name: 'currentBid', value: 'Final price', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
  ] : [
    {name: 'item', value: 'Won auction', direction: 'asc', sorted: true},
    {name: 'currentBid', value: 'Final price', direction: 'asc', sorted: true},
    {name: 'status', value: 'Status', direction: 'asc', sorted: true},
  ]);

  const getBids = useCallback(async () => {
    try {
      const response = await client.getMyBidsWinning({
        page: currentPage,
        size: 10,
      });
      setBids(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage, bids]);

  useEffect(() => {
    getBids();
  }, [currentPage])

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  const renderStatus = (e) => {
    if (e.status === "ENDED") {
      return <SmallOrangeTransparentButton onClick={() => history.push("/orders/lots?id=" + e.id)}>Take action</SmallOrangeTransparentButton>
    }
    if (e.status === "COMPLETED") {
      return <span className="status-completed">Completed</span>
    }
    if (e.status === "CANCELED") {
      return <span className="status-canceled">Canceled</span>
    }
  }

  return (
    <div className="content-relative">
      <div className="account-container">
        {!isMobile() && <AccountMenu tab={"win"}/>}
        <div>
          <div className="dashboard-box">
            <div className="caption">Winning bids</div>
            <div className="account-content">Here you find your winning bids.</div>
          </div>

          <div className="dashboard-box">
            <div className="caption">Winning bids</div>
            <div>
              <Table className="table" size="small">
                <TableHead>
                  <TableRow>
                    {headerFields.map(f =>
                      <TableCell key={f.name}>{f.value}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bids && bids.map((e, index) => (
                    <TableRow hover key={index}>
                      <TableCell onClick={() => history.push("/lot?id=" + e.id)}>{e.shortDescription}</TableCell>
                      {!isMobile() && <TableCell>{e.youSpentBids}</TableCell>}
                      {!isMobile() && <TableCell>{toEur(e.youSpentValue)}</TableCell>}
                      <TableCell>{toEur(e.currentAmount)}</TableCell>
                      <TableCell style={{minWidth: 100}}>{renderStatus(e)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={total}
                rowsPerPage={10}
                rowsPerPageOptions={10}
                page={currentPage}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onPageChange={onChangePage}
                className="table-pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}