import React from "react";
import "./BidPack.css";
import buyIcon from "../../images/packs/buy.png"
import redIcon from "../../images/packs/red.png"
import blueIcon from "../../images/packs/blue.png"
import greenIcon from "../../images/packs/green.png"
import goldIcon from "../../images/packs/gold.png"
import {toEur} from "../../utils/tools";
import {isMobile} from "../../utils/checkDevice";
import {useHistory} from "react-router-dom";

export const BidPack = ({pack}) => {
  const history = useHistory();

  const icons = [
    redIcon,
    blueIcon,
    greenIcon,
    goldIcon
  ];

  const handleRedirect = () => {
    history.push("/orders/bids/packs?id=" + pack.id)
  }

  return (
    <>
      {!isMobile() ?
        <div className="bid-pack-item" onClick={handleRedirect}>
          <div className="bid-pack-name">{pack?.name}</div>
          <div className="bid-pack-bids">{pack?.bids}-bid pack</div>
          <div className="bid-pack-per-item">({pack && toEur(+pack.price / +pack.bids)}/bid)</div>
          <div className="bid-pack-price">{pack && toEur(pack.price)}</div>
          <div className="bid-pack-buy-icon"><img src={buyIcon}/></div>
          {pack?.discount > 0 && <div className="bid-pack-discount">
            <div className="bid-pack-discount-inner">
              {pack && <img src={icons[pack.id - 3]}/>}
              <div className="bid-pack-discount-data">
                {pack?.discount}% OFF
              </div>
            </div>
          </div>}
        </div>
        :
        <div className="bid-pack-item" onClick={handleRedirect}>
          <div>
            <div className="bid-pack-name">{pack?.name}</div>
            <div className="bid-pack-bids">{pack?.bids}-bid pack</div>
            <div className="bid-pack-per-item">({pack && toEur(+pack.price / +pack.bids)}/bid)</div>
          </div>
          {pack?.discount > 0 ? <div className="bid-pack-discount">
            <div className="bid-pack-discount-inner">
              {pack && <img src={icons[pack.id -3]}/>}
              <div className="bid-pack-discount-data">
                {pack?.discount}% OFF
              </div>
            </div>
          </div> : <div/>}
          <div>
            <div className="bid-pack-price">{pack && toEur(pack.price)}</div>
            <div className="bid-pack-buy-icon"><img src={buyIcon}/></div>
          </div>
        </div>
      }
    </>
  )
}