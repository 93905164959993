import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {useSelector} from "react-redux";
import {LotItem} from "../../components/LotItem/LotItem";

export const LotList = () => {
  const history = useHistory();
  const [categoryId, setCategoryId] = useState(null);
  const [lots, setLots] = useState();
  const categories = useSelector(store => store.base.categories);

  const handleCategoryId = async () => {
    const id = new URLSearchParams(history.location.search).get('categoryId');
    if (id !== null) {
      try {
        setCategoryId(id);
        const response = await client.getLotsByCategory(id);
        setLots(response);
      } catch (e) {
        notification.warning(e.message);
      }
    } else {

    }
  }

  useEffect(() => {
    if (categoryId === null) {
      handleCategoryId();
    }
    history.listen((location) => {
      handleCategoryId();
    })
  }, [history]);


  const getCategoryName = () => {
    if (categoryId) {
      if (categoryId === "ending") {
        return <div className="header-caption">Ending 24-hours</div>
      } else {
        const c = categories && categories.find(e => e.id === +categoryId);
        return c && <div className="header-caption">{c.name}</div>
      }
    }
  }

  return (
    <div className="content-relative">
      {lots ? getCategoryName() : <div className="header-caption">Loading...</div>}
      <div className="lots-container">
        {lots ? lots.map((lot, i) => {
            return <LotItem key={i} lot={lot}/>
          }) :
          <>
            <LotItem lot={null}/>
            <LotItem lot={null}/>
            <LotItem lot={null}/>
          </>
        }
      </div>
    </div>
  )
}