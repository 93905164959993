import React, {useState} from "react";
import {
  InputAdornment, Modal,
  TextField
} from "@mui/material";
import {LockOutlined, MailOutline} from "@mui/icons-material";
import {OrangeButton} from "../../components/BtbButton/OrangeButton";
import {Link, useHistory} from "react-router-dom";
import {notification} from "../../components/Notification";
import {useDispatch} from "react-redux";
import {jwt} from "../../services/jwt";
import {client} from "../../services/client";
import {setAuth, setUser} from "../../store/actions/actions";
import CloseIcon from "@mui/icons-material/Close";
import {isMobile} from "../../utils/checkDevice";

export const Login = () => {
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    try {
      const response = await client.auth2fa({email, password});
      if (response.access_token !== '') {
        jwt.setJwtData(response.access_token, response.refresh_token);
        const user = await client.getCurrentUser();
        dispatch(setUser(user));
        dispatch(setAuth(true));

        if (user.status === "MEMBER") {
          history.push("/");
        }
      }
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    try {
      await client.requestResetPassword({
        email
      });
      handleCloseModal();
      notification.success("Reset link was sent to your email")
    } catch (e) {
      notification.warning(e.message);
    }
  }


  const modalBody = (
    <div className="modal">
      <div className="modal-close-button"><CloseIcon onClick={handleCloseModal}
                                                     fontSize={isMobile() ? "small" : "medium"}/></div>
      <div className="modal-caption">
        Reset password
      </div>
      <div className="">
        <div>Enter your email and we will send a reset link</div>
      </div>
      <form className="modal-reset-password-from" onSubmit={handleResetPassword}>
        <input id="email" className="input" autoComplete="off"/>
        <div className="modal-button-container">
          <OrangeButton type="submit">Send</OrangeButton>
        </div>
      </form>
    </div>
  );


  return (
    <div className="content-relative login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <div className="signup-caption">HI, THERE</div>
        <div className="signup-description">Login to your account below</div>

        <div className="signup-form-inputs">
          <div>
            <TextField
              id="email"
              type="email"
              label="Email address"
              variant="outlined"
              autoComplete='off'
              fullWidth
              error={errorEmail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline/>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              id="password"
              type="password"
              label="Password"
              autoComplete='off'
              variant="outlined"
              fullWidth
              error={errorPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined/>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <div className="signup-form-login">
          Don’t have an account? <Link to={"/signup"}>Register here</Link>
        </div>

        <div className="signup-form-button">
          <OrangeButton type="submit">LOGIN</OrangeButton>
        </div>
        <div className="signup-form-login">
          <div className="link" onClick={handleOpenModal}>Forgot your password?</div>
        </div>
      </form>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </div>
  )
}