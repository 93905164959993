import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AccountMenu} from "../../components/AccountMenu/AccountMenu";
import {isMobile} from "../../utils/checkDevice";
import "./Account.css";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {BlueButton} from "../../components/BtbButton/BlueButton";
import countries from "../../constants/country";
import {Autocomplete, Box, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
  inputRoot: {
    background: "#F7F5F9",
    color: "#37406F",
    border: "none !important",
    outline: "none !important",
    borderRadius: 18,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      outline: "none !important",
    },
  },
  popupIndicator: {},
  root: {
    border: "none !important",
    outline: "none !important",
  },
}));

export const PersonalProfile = () => {
  const user = useSelector(state => state.base.user);
  const [userInfo, setUserInfo] = useState({});
  const classes = useStyles()

  useEffect(() => {
    if (user) {
      setUserInfo(
        {
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          email: user.email,
          phone: user.phone,
          country: user.countryId,
          dateOfBirth: user.dateOfBirth,
          postcode: user.postcode,
          address: user.address,
          city: user.city,
        }
      );
    }
  }, [user]);

  const handleChangeFirstName = (e) => {
    setUserInfo({...userInfo, firstName: e.target.value});
  }

  const handleChangeLastName = (e) => {
    setUserInfo({...userInfo, lastName: e.target.value});
  }

  const handleChangePhone = (e) => {
    setUserInfo({...userInfo, phone: e.target.value});
  }

  const handleChangeAddress = (e) => {
    setUserInfo({...userInfo, address: e.target.value});
  }

  const handleChangePostcode = (e) => {
    setUserInfo({...userInfo, postcode: e.target.value});
  }

  const handleChangeCity = (e) => {
    setUserInfo({...userInfo, city: e.target.value});
  }

  const handleChangeCountry = (e, v) => {
    setUserInfo({...userInfo, country: v.id});
  }

  const updateUser = async (e) => {
    e.preventDefault();
    console.log(e);
    if (userInfo) {
      try {
        await client.updateUser(user.id, {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phone: userInfo.phone,
          address: userInfo.address,
          postal: userInfo.postcode,
          city: userInfo.city,
          countryId: +userInfo.country,
        });
      } catch (error) {
        notification.warning(error.message);
      }
    }
  }

  return (
    <div className="content-relative">
      <div className="account-container">
        {!isMobile() && <AccountMenu tab={"profile"}/>}

        <div>
          <form className="dashboard-box" onSubmit={updateUser}>
            <div className="caption">Personal Details</div>

            <div className="personal-details-flex">
              <div className="personal-details-table">
                <div className="personal-details-table-item">
                  <div>First name:</div>
                  <input
                    id="first-name"
                    className="input"
                    value={userInfo?.firstName}
                    onChange={handleChangeFirstName}
                    autoComplete="off"
                  />
                </div>
                <div className="personal-details-table-item">
                  <div>Username:</div>
                  <input id="username" className="input" value={userInfo?.username} readOnly/>
                </div>
                <div className="personal-details-table-item">
                  <div>Mobile:</div>
                  <input
                    id="phone"
                    className="input"
                    value={userInfo?.phone}
                    onChange={handleChangePhone}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="personal-details-table">
                <div className="personal-details-table-item">
                  <div>Last name:</div>
                  <input
                    id="last-name"
                    className="input"
                    value={userInfo?.lastName}
                    onChange={handleChangeLastName}
                    autoComplete="off"
                  />
                </div>
                <div className="personal-details-table-item">
                  <div>Country:</div>
                  {user &&
                  <Autocomplete
                    id="country"
                    classes={classes}
                    options={countries}
                    style={{marginLeft: 10, height: 40}}
                    fullWidth
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    defaultValue={countries.find(c => c.id === +user.countryId)}
                    onChange={handleChangeCountry}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete='off'
                        fullWidth
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  }
                </div>
                <div className="personal-details-table-item">
                  <div>Date of birth:</div>
                  <input id="date-of-birth" className="input" value={userInfo?.dateOfBirth} autoComplete="off"
                         readOnly/>
                </div>
              </div>
            </div>

            <div className="personal-details-table">
              <div className="personal-details-table-item full-width">
                <div>Email:</div>
                <input
                  id="email"
                  className="input"
                  value={userInfo?.email}
                  readOnly
                />
              </div>
              <div className="personal-details-table-item full-width">
                <div>Address:</div>
                <input
                  id="address"
                  className="input"
                  value={userInfo?.address}
                  onChange={handleChangeAddress}
                  autoComplete="off"
                />
              </div>
              <div className="personal-details-table-item full-width">
                <div>Postal code:</div>
                <input
                  id="post-code"
                  className="input"
                  value={userInfo?.postcode}
                  onChange={handleChangePostcode}
                  autoComplete="off"
                />
              </div>
              <div className="personal-details-table-item full-width">
                <div>City:</div>
                <input
                  id="city"
                  className="input"
                  value={userInfo?.city}
                  onChange={handleChangeCity}
                  autocomplete="off"
                />
              </div>
            </div>
            <div className="personal-details-buttons">
              <BlueButton type="submit">Save</BlueButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}