export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
    SIGNUP: 'users',
    REQUEST_RESET_PASSWORD: 'users/password/reset',
    CONFIRM_RESET_PASSWORD: 'users/password/confirm',
    UPDATE: (id) => `users/${id}`,
  },
  CATEGORIES: {
    GET_BY_ID: (id) => `categories/${id}`,
    POPULAR: 'categories/popular',
    ALL: 'categories',
  },
  LOTS: {
    GET_BY_ID: (id) => `lots/${id}`,
    GET_WIN_BY_ID: (id) => `lots/won/${id}`,
    GET_BY_CATEGORY: (id) => `lots/category/${id}`,
    PLACE_BID: (id) => `lots/${id}/bid`,
    TOGGLE_FAVORITE: (id) => `lots/${id}/favorite`,
    MY_FAVORITE: 'lots/favorite',
    CREATE_AUTOBIDDER: 'lots/autobidder',
    DISABLE_AUTOBIDDER: (id) => `lots/${id}/autobidder/disable`,
  },
  BID_PACKS: {
    ALL: 'packs',
  },
  BIDS: {
    MY_ACTIVE: 'lots/bids/my/active',
    MY_HISTORY: 'lots/bids/my/history',
    MY_WINNING: 'lots/winning',
    LOT_HISTORY: (id) => `lots/bids/history/${id}`,
  },
  ORDERS: {
    CREATE: 'orders',
    GET_ALL: 'orders',
    GET: (id) => `orders/${id}`,
    CAPTURE_PAYPAL_ORDER: (id) => `orders/paypal/capture/${id}`,
  },
  SSE: {
    STREAM: 'sse/public/stream'
  },
};
