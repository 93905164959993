import React from "react";
import "./AccountMenu.css";
import {useDispatch, useSelector} from "react-redux";
import iconDashboard from "../../images/menu-icons/dashboard.png";
import iconProfile from "../../images/menu-icons/profile.png";
import iconBids from "../../images/menu-icons/bids.png";
import iconWin from "../../images/menu-icons/win.png";
import iconAlerts from "../../images/menu-icons/alerts.png";
import iconFav from "../../images/menu-icons/fav.png";
import iconInvite from "../../images/menu-icons/invite.png";
import iconRewards from "../../images/menu-icons/rewards.png";
import iconLogout from "../../images/menu-icons/logout.png";
import {logout, setIsMobileMenuOpen} from "../../store/actions/actions";
import {useHistory} from "react-router-dom";

export const AccountMenu = ({tab}) => {
  const user = useSelector(store => store.base.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (link) => {
    history.push(link)
    dispatch(setIsMobileMenuOpen(false));
  }
  return (
    <div className="account-menu-container">

      <div className="account-menu-user-info">
        {user !== null &&
        <>
          <div className="account-menu-user-info-avatar">
            <img alt={user.name} src={user.avatar}/>
          </div>
          <div className="account-menu-user-info-username">{user.username}</div>
          <div className="account-menu-user-info-email">{user.email}</div>
        </>
        }
      </div>
      <div className="account-menu">
        <div className={tab === "dashboard" ? "account-menu-item account-menu-item-active" : "account-menu-item"}
             onClick={() => handleClick("/account/dashboard")}>
          <img src={iconDashboard}/>Dashboard
        </div>
        <div className={tab === "profile" ? "account-menu-item account-menu-item-active" : "account-menu-item"}
             onClick={() => handleClick("/account/profile")}>
          <img src={iconProfile}/>Personal Profile
        </div>
        <div className={tab === "bids" ? "account-menu-item account-menu-item-active" : "account-menu-item"}
             onClick={() => handleClick("/account/bids")}>
          <img src={iconBids}/>My Bids
        </div>
        <div className={tab === "win" ? "account-menu-item account-menu-item-active" : "account-menu-item"}
             onClick={() => handleClick("/account/win")}>
          <img src={iconWin}/>Winning Bids
        </div>
        <div className={tab === "alerts" ? "account-menu-item account-menu-item-active" : "account-menu-item"}
             onClick={() => handleClick("/account/alerts")}>
          <img src={iconAlerts}/>My Alerts
        </div>
        <div className={tab === "favorites" ? "account-menu-item account-menu-item-active" : "account-menu-item"}
             onClick={() => handleClick("/account/favorites")}>
          <img src={iconFav}/>My Favorites
        </div>
        <div className={tab === "invite" ? "account-menu-item account-menu-item-active" : "account-menu-item"}
             onClick={() => handleClick("/account/invite")}>
          <img src={iconInvite}/>Invite & Earn
        </div>
        <div className={tab === "rewards" ? "account-menu-item account-menu-item-active" : "account-menu-item"}
             onClick={() => handleClick("/account/rewards")}>
          <img src={iconRewards}/>Promotions & Rewards
        </div>
        {user !== null &&
        <div className="account-menu-item" onClick={() => {
          dispatch(logout());
          dispatch(setIsMobileMenuOpen(false));
        }}><img src={iconLogout}/>Logout</div>
        }
      </div>
    </div>
  )
}