import React, {useCallback, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import "./Orders.css";
import "../account/Account.css";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {toEur} from "../../utils/tools";
import QRCode from "qrcode.react";

export const OrderStatus = () => {
  const [order, setOrder] = useState();
  const history = useHistory();

  const getOrder = useCallback(async (id) => {
    try {
      const response = await client.getOrder(id);
      setOrder(response);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [order])

  useEffect(() => {
    const id = new URLSearchParams(history.location.search).get('id');
    if (id !== null) {
      getOrder(id);
    }
  }, [])

  return (
    <div className="content-relative">
      {order ? (order.status === "PENDING" ?
          <div>
            <div className="box">
              <div className="caption">{order.paymentMethod} payment</div>
              <div className="account-content">You have chosen {order.paymentMethod} payment. Pay using wallet id or QR
                code with the full amount
                within 1 hour.
              </div>
            </div>
            <div className="box">
              <div className="caption">Your order</div>
              <div>
                <Table className="table" size="small">
                  <TableBody>
                    <>
                      <TableRow hover>
                        <TableCell>Total order</TableCell>
                        <TableCell style={{textAlign: "right"}}>{toEur(order.amount)}</TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>{order.paymentMethod} rate</TableCell>
                        <TableCell style={{textAlign: "right"}}>{toEur(order.currencyRate)}</TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell style={{fontWeight: 600}}>{order.paymentMethod} to pay</TableCell>
                        <TableCell style={{textAlign: "right", fontWeight: 600}}>
                          {order.paymentMethod} {order.cryptoAmount}
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                </Table>
              </div>
              <div className="qr-code-container">
                <div className="qr-code-caption">Pay to {order.paymentMethod} wallet:</div>
                <div className="qr-code-address">{order.cryptoAddress}</div>
                <div>
                  <QRCode value={order.cryptoAddress}/>
                </div>
                <div className="qr-code-link"><Link to={
                  order.type === "BID_PACK" ?
                  "/orders/bids/packs" : "/account/win"
                }>Change payment</Link></div>
              </div>
            </div>
          </div>
          : order.status === "PAID" ?
            <div className="box">
              <div className="receipt">
                <div className="caption center">Receipt of payment</div>
                <Table className="table" size="small">
                  <TableBody>
                    <>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell/>
                        <TableCell style={{textAlign: "right"}}>Paid</TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>{order.name}</TableCell>
                        <TableCell/>
                        <TableCell style={{textAlign: "right"}}>{toEur(order.amount)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell/>
                        <TableCell style={{textAlign: "right"}}>Total paid:</TableCell>
                        <TableCell style={{textAlign: "right"}}>{toEur(order.amount)}</TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                </Table>
                <div className="receipt-data-table">
                  <Table className="table" size="small">
                    <TableBody>
                      <>
                        <TableRow>
                          <TableCell>Payment method:</TableCell>
                          <TableCell>{order.paymentMethod}</TableCell>
                          <TableCell>Date:</TableCell>
                          <TableCell style={{textAlign: "right"}}>{order.createdAt}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Receipt number:</TableCell>
                          <TableCell>{order.externalId}</TableCell>
                          <TableCell>Time: </TableCell>
                          <TableCell style={{textAlign: "right"}}>{order.createdAt}</TableCell>
                        </TableRow>
                      </>
                    </TableBody>
                  </Table>
                </div>
                <div className="receipt-footer">
                  <p>Rocket Solutions LLC</p>
                  <p>16192 Coastal Highway</p>
                  <p>Lewes, DE 19958</p>
                  <p>USA</p>
                  <p>support@bitethebrit.com</p>
                  <p>+1 302 476 2564</p>
                </div>
              </div>
            </div>
            :
            <div>{order.status}</div>
      ) : <div className="box">
        <div className="caption">Loading...</div>
      </div>}
    </div>
  )
}