import React from "react";
import {isMobile} from "../../utils/checkDevice";
import {useHistory} from "react-router-dom";

export const CategoryItem = ({category}) => {
  const history = useHistory();

  return (
    <div className="category-item" onClick={() => {
      isMobile() ?
        history.push("/lots?categoryId=" + category.id)
        :
      document.getElementById("cat-detailed-" + category.id).scrollIntoView();
    }}>
      <div className="category-item-inner">
        <img alt={category.name} className="category-item-icon" src={category.icon}/>
        <div>{category.name}</div>
      </div>
    </div>
  )
}