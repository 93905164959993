import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const BlueButton = withStyles(() => ({
  root: {
    textTransform: "none !important",
    color: "#FFFFFF !important",
    background: "linear-gradient(0deg, #3DA9F5 0%, #683DF5 100%)",
    '&:hover': {
      background: "linear-gradient(0deg, #3DA9F5 10%, #683DF5 90%);",
    },
    borderRadius: "25px !important",
    fontSize: 18,
    width: 230,
    height: 50,
    boxSizing: "border-box"
  },
}))(Button);