import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const OrangeButton = withStyles(() => ({
  root: {
    textTransform: "none !important",
    color: "white !important",
    background: "linear-gradient(359.48deg, #FF8359 0.44%, #FFDF40 99.56%)",
    '&:hover': {
      background: "linear-gradient(359.48deg, #FF8359 10.44%, #FFDF40 99.56%)",
    },
    borderRadius: "25px !important",
    fontSize: 18,
    width: 230,
    height: 50,
    boxSizing: "border-box"
  },
}))(Button);