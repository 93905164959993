import React from "react";
import {useSelector} from "react-redux";
import {AccountMenu} from "../../components/AccountMenu/AccountMenu";
import {isMobile} from "../../utils/checkDevice";
import {Share} from "../../components/Share/Share";

export const InviteAndEarn = () => {
  const user = useSelector(state => state.base.user);

  return (
    <div className="content-relative">
      <div className="account-container">
        {!isMobile() && <AccountMenu tab={"invite"}/>}
        <div>
          <div className="dashboard-box">
            <div className="caption">Welcome to the BiteTheBrit Referral program.</div>
            <div className="account-content">Have friends sign up using your unique link and earn free bids.</div>
          </div>

          <Share/>
        </div>
      </div>
    </div>
  )
}