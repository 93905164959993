import React from "react";
import balanceIcon from "../../images/dashboard/balance.png";
import earnedIcon from "../../images/dashboard/earned.png";
import {isMobile} from "../../utils/checkDevice";
import usedIcon from "../../images/dashboard/used.png";
import {useSelector} from "react-redux";

export const BidBox = ({title}) => {
  const user = useSelector(state => state.base.user);

  return(
    <div className="dashboard-box">
      <div className="caption">{title}</div>
      <div className="dashboard-items-container">
        <div className="dashboard-item">
          <img src={balanceIcon}/>
          <div className="dashboard-item-balance">{user?.wallet.bidBalance}</div>
          <div className="dashboard-item-caption">Bid balance</div>
        </div>
        <div className="dashboard-item">
          <img src={earnedIcon}/>
          <div className="dashboard-item-balance">{user?.wallet.bidEarned}</div>
          <div className="dashboard-item-caption">{isMobile() ? "Free bids" : "Earned free bids"}</div>
        </div>
        <div className="dashboard-item">
          <img src={usedIcon}/>
          <div className="dashboard-item-balance">{user?.wallet.bidUsed}</div>
          <div className="dashboard-item-caption">Bid used</div>
        </div>
      </div>
    </div>
  )
}