import React from "react";
import {TransparentButton} from "../BtbButton/TransparentButton";
import {LotItem} from "../LotItem/LotItem";
import {isMobile} from "../../utils/checkDevice";
import {useHistory} from "react-router-dom";

export const CategoryItemDetailed = ({category}) => {
  const history = useHistory();
  return (
    <div className="popular-categories-detailed-item-container">
      {category.bg && category.bg !== "" && !isMobile() &&
      <img alt={category.name} className="category-item-detailed-bg" src={category.bg}/>
      }
      <div className="category-item-detailed" id={"cat-detailed-" + category.id}>
        <div className="category-item-detailed-content">
          <div>
            <div className="category-item-detailed-icon-container">
              {!isMobile() && <img alt={category.name} className="category-item-icon" src={category.icon}/>}
              <div className="category-item-detailed-caption-container">
                <div className="category-item-detailed-caption">{category.name}</div>
                {!isMobile() &&  <div className="category-item-detailed-description">{category.description}</div>}
              </div>
            </div>
            <div>
              {!isMobile() && <TransparentButton onClick={() => history.push("/lots?categoryId=" + category.id)}>See all {category.name} Auctions</TransparentButton>}
            </div>
          </div>

          <div>
            {category.lots && category.lots.map((e, i) => {
              return <LotItem key={i} lot={e}/>
            })}
          </div>

        </div>
      </div>
    </div>
  )
}