import React, {useCallback, useEffect, useState} from "react";
import {AccountMenu} from "../../components/AccountMenu/AccountMenu";
import {isMobile} from "../../utils/checkDevice";
import "./Account.css";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {toEur} from "../../utils/tools";
import {useHistory} from "react-router-dom";

export const MyFavorites = () => {
  const [tabValue, setTabValue] = useState("tab1");
  const [tabContent, setTabContent] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);
  const [bids, setBids] = useState();
  const history = useHistory();

  const [headerFields, setHeaderFields] = useState(!isMobile() ? [
    {name: 'item', value: 'Item', direction: 'asc', sorted: true},
    {name: 'totalBids', value: 'Total bids', direction: 'asc', sorted: true},
    {name: 'valueBids', value: 'Value bids', direction: 'asc', sorted: true},
    {name: 'currentBid', value: 'Current bid', direction: 'asc', sorted: true},
    {name: 'highBidder', value: 'High bidder', direction: 'asc', sorted: true},
  ] : [
    {name: 'item', value: 'Item', direction: 'asc', sorted: true},
    {name: 'currentBid', value: 'Current bid', direction: 'asc', sorted: true},
    {name: 'highBidder', value: 'High bidder', direction: 'asc', sorted: true},
  ])

  const getBids = useCallback(async () => {
    try {
      const response = await client.getLotsFavorite({
        page: currentPage,
        size: 10,
      });
      setBids(response.content);
      setTotal(response.totalElements)
    } catch (e) {
      notification.warning(e.message);
    }
  }, [currentPage]);

  useEffect(() => {
    getBids();
  }, [currentPage])


  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <div className="content-relative">
      <div className="account-container">
        {!isMobile() && <AccountMenu tab={"favorites"}/>}
        <div>
          <div className="dashboard-box">
            <div className="caption">My favorites</div>
            <div className="account-content">Here you saved you favorite auctions</div>
          </div>

          <div className="dashboard-box">
            <div className="caption">My favorites</div>
            <div>
              <Table className="table" size="small">
                <TableHead>
                  <TableRow>
                    {headerFields.map(f =>
                      <TableCell key={f.name}>{f.value}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bids && bids.map((e, index) => (
                    <TableRow hover key={index}>
                      <TableCell onClick={() => history.push("/lot?id=" + e.id)}>{e.shortDescription}</TableCell>
                      {!isMobile() && <TableCell>{e.youSpentBids}</TableCell>}
                      {!isMobile() && <TableCell>{toEur(e.youSpentValue)}</TableCell>}
                      <TableCell>{toEur(e.currentAmount)}</TableCell>
                      <TableCell>{e.highBidder}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={total}
                rowsPerPage={10}
                rowsPerPageOptions={10}
                page={currentPage}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onPageChange={onChangePage}
                className="table-pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}