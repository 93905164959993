import React from "react";
import {useSelector} from "react-redux";
import {AccountMenu} from "../../components/AccountMenu/AccountMenu";
import {isMobile} from "../../utils/checkDevice";

export const PromotionsAndRewards = () => {
  const user = useSelector(state => state.base.user);

  return (
    <div className="content-relative">
      <div>
        {!isMobile() && <AccountMenu tab={"rewards"}/>}
      </div>
    </div>
  )
}