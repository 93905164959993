import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const SmallTransparentButton = withStyles(() => ({
  root: {
    textTransform: "none !important",
    color: "#6979F8 !important",
    border: "1px solid #6979F8 !important",
    "&:hover":{
      border: "1px solid #6979F8 !important",
    },
    borderRadius: "15px !important",
    fontSize: 16,
    paddingLeft: "24px !important",
    paddingRight: "24px !important",
    height: "30px !important"
  },
}))(Button);