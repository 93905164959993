import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users
  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);
  signup = (data) => api.post(API_URLS.USERS.SIGNUP, data);
  requestResetPassword = (data) => api.post(API_URLS.USERS.REQUEST_RESET_PASSWORD, data);
  confirmResetPassword = (data) => api.post(API_URLS.USERS.CONFIRM_RESET_PASSWORD, data);
  updateUser = (id, data) => api.put(API_URLS.USERS.UPDATE(id), data);

  //Categories
  getAllCategories = () => api.get(API_URLS.CATEGORIES.ALL);
  getPopularCategories = () => api.get(API_URLS.CATEGORIES.POPULAR);
  getCategoryById = (id) => api.get(API_URLS.CATEGORIES.GET_BY_ID(id));

  //Lots
  getLotById = (id) => api.get(API_URLS.LOTS.GET_BY_ID(id));
  getWinLotById = (id) => api.get(API_URLS.LOTS.GET_WIN_BY_ID(id));
  getLotsByCategory = (id) => api.get(API_URLS.LOTS.GET_BY_CATEGORY(id));
  placeBid = (id) => api.post(API_URLS.LOTS.PLACE_BID(id));
  getLotsFavorite = (params) => api.get(API_URLS.LOTS.MY_FAVORITE, {params})
  toggleFavorite = (id) => api.put(API_URLS.LOTS.TOGGLE_FAVORITE(id));
  createAutobidder = (data) => api.post(API_URLS.LOTS.CREATE_AUTOBIDDER, data);
  disableAutobidder = (id) => api.put(API_URLS.LOTS.DISABLE_AUTOBIDDER(id));


  //Bid packs
  getBidPacks = () => api.get(API_URLS.BID_PACKS.ALL);

  //Bids
  getMyBidsActive = (params) => api.get(API_URLS.BIDS.MY_ACTIVE, {params})
  getMyBidsHistory = (params) => api.get(API_URLS.BIDS.MY_HISTORY, {params})
  getMyBidsWinning = (params) => api.get(API_URLS.BIDS.MY_WINNING, {params})
  getLotBidsHistory = (id, params) => api.get(API_URLS.BIDS.LOT_HISTORY(id), {params})

  //Orders
  createOrder = (data) => api.post(API_URLS.ORDERS.CREATE, data);
  getOrder = (id) => api.get(API_URLS.ORDERS.GET(id));
  capturePaypalOrder = (id) => api.put(API_URLS.ORDERS.CAPTURE_PAYPAL_ORDER(id));
  getAllOrders = (params) => api.get(API_URLS.ORDERS.GET_ALL, {params});

  //SSE
  getStream = () => api.setStream(API_URLS.SSE.STREAM)

}

export const client = new Client();
