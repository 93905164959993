import React, {useCallback, useEffect, useState} from "react";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {BidPack} from "../../components/BidPack/BidPack";
import "./Orders.css";
import {useHistory} from "react-router-dom";
import {Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {toEur} from "../../utils/tools";
import {PaymentMethods} from "../../components/PaymentMethods/PaymentMethods";

export const BidPacksOrder = () => {
  const history = useHistory();
  const [bidPacks, setBidPacks] = useState();
  const [pack, setPack] = useState();
  const [termsApproved, setTermsApproved] = useState(false);
  let id = new URLSearchParams(history.location.search).get('id');

  const [headerFields, setHeaderFields] = useState([
    {name: 'item', value: 'Item', direction: 'asc', sorted: true},
    {name: 'amount', value: 'Amount', direction: 'asc', sorted: true},
    {name: 'price', value: 'Price', direction: 'asc', sorted: true},
  ]);

  const getBidPacks = useCallback(async () => {
    try {
      const response = await client.getBidPacks();
      setBidPacks(response);
    } catch (e) {
      notification.warning(e.message);
    }
  }, [bidPacks]);

  useEffect(() => {
    getBidPacks()
  }, []);

  useEffect(() => {
    if (!id) {
      id = 2;
    }
    if (bidPacks) {
      const _pack = bidPacks.filter((e) => {
        return +id === +e.id
      })
      setPack(_pack[0]);
    }
  }, [bidPacks, id]);

  const handleChangeTermsApprove = () => {
    setTermsApproved(!termsApproved);
  }

  return (
    <div className="content-relative">
      <div className="orders-pack-container">
        <div className="caption">Buy Bids</div>
        <div className="orders-pack-container-inner">
          {bidPacks ? bidPacks.map((_pack, i) => {
            return <BidPack key={i} pack={_pack} redirect={true}/>
          }) : <BidPack/>}
        </div>
      </div>

      <div className="box">
        <div className="caption">Your order</div>
        <div>
          <Table className="table" size="small">
            <TableHead>
              <TableRow>
                {headerFields.map(f =>
                  <TableCell key={f.name}>{f.value}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {pack &&
              <>
                <TableRow hover>
                  <TableCell style={{width: "60%"}}>{pack.name}</TableCell>
                  <TableCell style={{width: "20%"}}>1</TableCell>
                  <TableCell style={{width: "20%"}}>{toEur(pack.price)}</TableCell>
                </TableRow>
                <TableRow hover>
                  <TableCell style={{width: "60%"}}/>
                  <TableCell style={{width: "20%", fontWeight: 600}}>Total to pay</TableCell>
                  <TableCell style={{width: "20%", fontWeight: 600}}>{toEur(pack.price)}</TableCell>
                </TableRow>
              </>
              }
            </TableBody>
          </Table>
        </div>

        <div className="order-terms-container">
          <FormControlLabel control={<Checkbox checked={termsApproved} onChange={handleChangeTermsApprove}/>}
                            label="I approve the terms and conditions for this purchase"/>
        </div>
      </div>

      <PaymentMethods pack={pack} terms={termsApproved}/>

    </div>
  )
}