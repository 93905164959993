import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {isMobile} from "../../utils/checkDevice";
import copyIcon from "../../images/copy-icon.png";
import {BlueButton} from "../BtbButton/BlueButton";
import "../../pages/account/Account.css";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton, LineIcon, LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import {notification} from "../Notification";

export const Share = () => {
  const user = useSelector(state => state.base.user);
  const shareTitle = "Penny auction";
  const [shareUrl, setShareUrl] = useState();

  const handleCopy = () => {
    navigator.clipboard.writeText(shareUrl);
    notification.success("Invite link copied");
  }

  useEffect(() => {
    if (user !== null) {
      setShareUrl("https://bitethebrit.com/signup?invite=" + user.username);
    }
  }, [user]);

  return(
    <div className="dashboard-box account-share-box">
      <div className="caption">Share your unique referral link</div>
      <div className="account-share-link-container">
        <div>{shareUrl && shareUrl}</div>
        {isMobile() ? <img src={copyIcon} onClick={handleCopy} className="copy-icon"/> :
          <BlueButton onClick={handleCopy}>Copy Link</BlueButton>
        }
      </div>
      <div className="account-share-container">
        <div className="account-share-caption">{isMobile()? "or share using:" : "Share to:"}</div>
        <FacebookShareButton
          url={shareUrl}
          quote={shareTitle}
          className="share-button"
        >
          <FacebookIcon size={40} round />
        </FacebookShareButton>
        <TelegramShareButton
          url={shareUrl}
          title={shareTitle}
          className="share-button"
        >
          <TelegramIcon size={40} round />
        </TelegramShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={shareTitle}
          separator=":: "
          className="share-button"
        >
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>
        <LineShareButton
          url={shareUrl}
          title={shareTitle}
          className="share-button"
        >
          <LineIcon size={40} round />
        </LineShareButton>
        <EmailShareButton
          url={shareUrl}
          subject={shareTitle}
          body={shareTitle}
          className="share-button"
        >
          <EmailIcon size={40} round />
        </EmailShareButton>
      </div>
    </div>
  )

}