import {
  LOG_OUT,
  SET_AUTH,
  SET_USER,
  SET_CATEGORIES,
  IS_MOBILE_MENU_OPEN,
  SET_UPDATED_LOTS
} from './actionTypes';
import {createAction} from 'redux-actions'
import {jwt} from '../../services/jwt'

const actionList = {
  logout: createAction(LOG_OUT),
  setAuth: createAction(SET_AUTH),
  setUser: createAction(SET_USER),
  setCategories: createAction(SET_CATEGORIES),
  setIsMobileMenuOpen: createAction(IS_MOBILE_MENU_OPEN),
  setUpdatedLots: createAction(SET_UPDATED_LOTS)
}

export const logout = () => {
  localStorage.clear()
  sessionStorage.clear();
  jwt.destroy()
  return actionList.logout()
}

export const setAuth = (isAuth) => {
  return actionList.setAuth(isAuth)
}

export const setUser = (user) => {
  return actionList.setUser(user)
}

export const setCategories = (categories) => {
  return actionList.setCategories(categories)
}

export const setIsMobileMenuOpen = (isMobileMenuOpen) => {
  return actionList.setIsMobileMenuOpen(isMobileMenuOpen)
}

export const setUpdatedLots = (lot) => {
  return actionList.setUpdatedLots(lot)
}