import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const OrangeTransparentButton = withStyles(() => ({
  root: {
    textTransform: "none !important",
    color: "#ffac4e !important",
    border: "1px solid #ffac4e !important",
    borderRadius: "25px !important",
    fontSize: 18,
    width: 230,
    height: 50,
    boxSizing: "border-box"
  },
}))(Button);