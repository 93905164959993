import React, {useState} from "react";
import {
  Autocomplete,
  Box, Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import countries from "../../constants/country";
import {LockOutlined, MailOutline, PermIdentity} from "@mui/icons-material";
import {OrangeButton} from "../../components/BtbButton/OrangeButton";
import {Link, useHistory} from "react-router-dom";
import {client} from "../../services/client";
import {jwt} from "../../services/jwt";
import {setAuth, setUser} from "../../store/actions/actions";
import {notification} from "../../components/Notification";
import {useDispatch} from "react-redux";

export const Signup = () => {
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [terms, setTerms] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangeTerms = () => {
    setTerms(!terms);
  }

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!terms) {
      notification.warning("You must approve terms");
      return;
    }
    const country = e.target.country.value;
    if (!country) {
      notification.warning("Please choose country");
      return;
    }
    const countryId = countries.find(c => c.name === country).id;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const username = e.target.username.value;
    const ref = new URLSearchParams(history.location.search).get('invite');
    try {
      const response = await client.signup({
        countryId: countryId,
        email: email,
        password: password,
        username: username,
        ref: ref
      });
      if (response.access_token !== '') {
        jwt.setJwtData(response.access_token, response.refresh_token);
        const user = await client.getCurrentUser();
        dispatch(setUser(user));
        dispatch(setAuth(true));

        if (user.status === "MEMBER") {
          history.push("/account/dashboard");
        }
      }
    } catch (e) {
      notification.warning(e.message);
    }
  }

  return (
    <div className="content-relative signup-container">
      <form className="signup-form" onSubmit={handleSignup}>
        <div className="signup-caption">Welcome</div>
        <div className="signup-description">Register your new BiteTheBrit account here.</div>

        <div className="signup-form-inputs">
          <div>
            <Autocomplete
              id="country"
              options={countries}
              fullWidth
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.iso.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.iso.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  autoComplete='off'
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>

          <div>
            <TextField
              id="username"
              label="Username"
              variant="outlined"
              autoComplete='off'
              fullWidth
              hintText="Test"
              error={errorUsername}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PermIdentity />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              id="email"
              type="email"
              label="Email address"
              variant="outlined"
              autoComplete='off'
              fullWidth
              error={errorEmail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              id="password"
              type="password"
              label="Password"
              autoComplete='off'
              variant="outlined"
              fullWidth
              error={errorPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <div className="signup-form-terms">
          <FormControlLabel control={<Checkbox checked={terms} onClick={handleChangeTerms} />} label="I agree to the Terms of Service and Privacy Policy" />
        </div>
        <div className="signup-form-button">
          <OrangeButton type="submit">Register</OrangeButton>
        </div>
        <div className="signup-form-login">
          Already have an account? <Link to={"/login"}>Login here</Link>
        </div>
      </form>
    </div>
  )
}