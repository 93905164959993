import React, {useCallback, useEffect, useState} from "react";
import {CategoryItem} from "../../components/CategoryItem/CategoryItem";
import {CategoryItemDetailed} from "../../components/CategoryItemDetailed/CategoryItemDetailed";
import {isMobile} from "../../utils/checkDevice";
import {useSelector} from "react-redux";
import {OrangeTransparentButton} from "../../components/BtbButton/OrangeTransparentButton";
import {OrangeButton} from "../../components/BtbButton/OrangeButton";
import {useHistory} from "react-router-dom";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {WhiteButton} from "../../components/BtbButton/WhiteButton";

export const Home = () => {
  const history = useHistory();
  const user = useSelector(store => store.base.user);
  const [loading, setLoading] = useState(false);
  const [popularCategories, setPopularCategories] = useState();

  const getCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await client.getPopularCategories();
      setPopularCategories(response);
      setLoading(false);
    } catch (e) {
      notification.warning(e.message);
      setLoading(false);
    }
  }, [popularCategories]);


  useEffect(() => {
    // getCategories();
  }, []);


  return (
    <>
      <div className="content">
        <div className="logo logo-landing" style={{margin: "0 auto"}}/>
        <div className="landing-box">We are re-working the current site to make it faster, better and more attractive. Please come back again.</div>
        <div className="landing-faq" onClick={() => window.open("https://bitethebrit.freshdesk.com/support/home")}>To learn the FAQs - you can visit our support page here.</div>
        <div className="home-header-content">
          {isMobile() ?
            <>
              {user ?
                <>
                  {/*<div>Welcome, now you <br/> can start bidding...</div>*/}
                </>
                :
                <>
                  {/*<div>A Wee better way <br/> to get your gadgets</div>*/}
                  {/*<div>*/}
                  {/*  Register and get your next <br/> favorite item for one penny!*/}
                  {/*</div>*/}
                  <div className="home-header-buttons">
                    {/*<div><OrangeTransparentButton onClick={() => history.push("login")}>Login</OrangeTransparentButton>*/}
                    {/*</div>*/}
                    {/*<div><OrangeButton onClick={() => history.push("signup")}>Register</OrangeButton></div>*/}
                  </div>
                </>
              }
            </> :
            <>
              {/*<div>Next Generation Penny Auction</div>*/}
              {/*<div>A Wee better way <br/> to get your gadgets</div>*/}
              {/*<div>*/}
              {/*  BiteTheBrit Penny Auctions is where everyone <br/> goes to shop and could get their next iPhone*/}
              {/*  for <br/> a*/}
              {/*  penny!*/}
              {/*</div>*/}
            </>}
        </div>

        <div className="popular-categories-container">
          {/*<div>Most Popular Categories</div>*/}
          {loading ? <div className="loader center"/> :
            <div>
              {popularCategories && popularCategories.map((c, i) => {
                return <CategoryItem key={i} category={c}/>
              })}
            </div>
          }
        </div>
      </div>

      <div className="popular-categories-detailed-container">
        {popularCategories && popularCategories.map((c, i) => {
          return <>
            {i === 1 ?
              <>
                <CategoryItemDetailed key={i} category={c}/>
                {(!isMobile() && !user) && <div  key={"m" + i} className="register-landing-box">
                  <div>
                    <div>
                      Register for Free & Start Bidding Now!
                    </div>
                    <div>From cars to diamonds to iPhones, we have it all.</div>
                  </div>
                  <div><WhiteButton onClick={() => history.push("/signup")}>Register</WhiteButton></div>
                </div>}
              </> :
              <CategoryItemDetailed key={i} category={c}/>}
          </>
        })}
      </div>
    </>
  )
}