import React from "react";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {ArrowForwardIosSharp, ExpandMore} from "@mui/icons-material";
import {Typography} from "@mui/material";
import {styled} from '@mui/material/styles';
import {isMobile} from "../../utils/checkDevice";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid rgba(101, 93, 202, 0.301961) !important`,
  borderRadius: "10px !important",
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharp/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor: "#EBF2FF",
  padding: isMobile() && "0 5px",
  borderRadius: "10px !important",
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  backgroundColor: "#EBF2FF",
  borderTop: '1px solid rgba(101, 93, 202, 0.301961)',
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
}));

export const Faq = () => {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="content-relative">
      <div className="faq">
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              How does this work?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>To participate in our penny auctions, you must first register. This is free and takes less than 30
                seconds!
              </p>
              <p>Each bid raises the auction price by €0.01 and resets the clock for a maximum of 20 seconds.</p>

              <p>Be the last to place a bid when the timer counts down, and you win!</p>

              <p>Bids start at just €1.00 each and can be purchased even less with our discounted packages.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              How many accounts can I have?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>There is a limit to one account per household/IP address. For example, if you operate more than one
                account, your account(s) will be closed, and all bids and/or wins will be null and void.</p>
              <p>Why do we do this? To keep a fair playing field for all users, we have found that having multiple
                accounts can provide an unfair advantage to other participants. For example, obtaining additional bids
                through our refer-a-friend program and free registration credits is strictly prohibited.</p>
              <p>If you have any additional questions, please contact us at support@bitethebrit.com.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              How many auctions can I win?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>Two regular auctions per day, eight per week. (Two valued at €150 or more over per week).</p>
              <p>Please keep this in mind when participating in auctions.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              How much is shipping?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>It all depends on your country of destination. We will inform you of the shipping cost when an auction
                is won.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              How fast do you ship?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>Unusually fast! When your item is shipped, you will receive a confirmation email with the tracking
                info. Please allow 2-10 days for the item to arrive. Delivery times vary; any delivery time indicated is
                provided only as a guide and is not guaranteed. </p>
            </div>
          </AccordionDetails>
        </Accordion>


        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              Are all the auctioned items brand new?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>All items auctioned at BiteTheBrit are brand name, brand new, and factory sealed!
              </p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              Are the products under warranty?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>All items come with a standard manufacturer’s warranty only. Bid packs, gift cards, and occasional
                vacation trips auctioned are excluded.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              Item substitution policy

            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>Many of your wins are shipped directly from major retailers; however, availability fluctuates
                dramatically, and if an item is currently unavailable, some auction wins will be substituted at the
                Retail Price minus the bid price. In the case of silver and gold products, vendor and availability vary.
                We will notify you if this happens and offer a coin of similar value. </p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              What Payment methods are accepted?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>Visa, MasterCard using PayPal and cryptocurrency BTC, ETH, and USDT (ERC-20)</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              I Won! What now?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>Congratulations!
                You will first receive an email from one of our support members about the following steps to claim your
                product. Please note your wins are confirmed by one of our team members within 48 hours of you winning
                the auction.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              What do I Pay For?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>After winning an auction, you pay the final auction price and shipping. That’s it! </p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              Do I have to pay sales tax?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>Sales tax on all physical items may apply due to country and import restrictions.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              Can I return the items I have won or bought?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>If you are not satisfied with any auction item you have purchased, you may return it for a full refund
                within 14 days, unused and in its original package. A full refund is considered to be the price you paid
                for the winning item at the end of the auction. </p>
              <p>However, this does NOT include the cost of bids that may have been used to place a bid. You must email
                us at support@bitethe.com to receive return/refund instructions before returning any item.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              Who am I bidding against?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>You are bidding against registered users like yourself from all around the globe.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              I received some free bids when I signed up. Are there any other ways to earn free bids?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>Absolutely! A great way to earn bids is to become a fan on www.facebook.com/bitethebrit where we hold
                weekly contests giving away free bids. If you have won an auction, you qualify to receive free bids by
                submitting testimonials. Additionally, our refer-and-earn program allows users to receive 10 free bids
                when the friend they have referred signs up and purchases a bid pack!</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              Why didn’t my bid register/timers run slow?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>Make sure you do not download large files such as audio or video files while bidding. We recommend you
                bid at least 5 seconds before the timer reaches zero and cannot guarantee your bid will be registered
                during the last seconds.</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="faq-caption">
              <div className="faq-icon"/>
              What is the auto bidder function?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="faq-details">
              <p>You may activate auto bidders on auctions you want to bid without being present. You control the number
                of bids and from what price until what price you want to place automatic bids.</p>
            </div>
          </AccordionDetails>
        </Accordion>

      </div>

    </div>
  )
}