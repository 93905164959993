import React, {useCallback, useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {client} from "../../services/client";
import {useHistory} from "react-router-dom";

export const PaypalReturnUrl = () => {
  const history = useHistory();

  useEffect(async () => {
    const token = new URLSearchParams(history.location.search).get('token');
    if (token !== null) {
      try {
        await client.capturePaypalOrder(token);
        notification.success("Order paid");
      } catch (e) {
        notification.warning(e.message);
        history.push("/dashboard");
      }
    } else {
      notification.warning("Wrong token");
    }
    history.push("/dashboard");
  }, []);

  return (
    <></>
  )
}