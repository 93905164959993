import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const TransparentButton = withStyles(() => ({
  root: {
    color: "#584C8A",
    border: "1px solid rgba(181, 156, 228, 0.501961);",
    "&:hover":{
      border: "1px solid rgba(181, 156, 228, 0.501961);",
    },
    borderRadius: 30,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    height: 50
  },
}))(Button);