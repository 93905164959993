import React, {useCallback, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setCategories, setUpdatedLots, setUser} from "../../../store/actions/actions";
import {client} from "../../../services/client";
import {Header} from "../../Header/Header";
import headerImg from "../../../images/header.jpg";
import headerImgHome from "../../../images/header-home.jpg";
import headerImgMobile from "../../../images/header-mobile.png";
import {isMobile} from "../../../utils/checkDevice";
import {HeaderMobile} from "../../Header/HeaderMobile";
import {Footer} from "../../Footer/Footer";
import {FooterMobile} from "../../Footer/FooterMobile";

export const MainLayout = ({children}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(store => store.base.user);
  const categories = useSelector(store => store.base.categories);

  const getCategories = useCallback(async () => {
    if (categories === null) {
      const response = await client.getAllCategories();
      dispatch(setCategories(response));
    }
  }, [categories]);


  const fetchCurrentUser = useCallback(async () => {
    const isAuth = !!localStorage.accessToken && !!localStorage.refreshToken;
    if (user === null && isAuth) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);

  useEffect(() => {
    fetchCurrentUser();
    getCategories();
  }, []);

  useEffect(() => {
    const sse = client.getStream();
    sse.onmessage = function (event) {
      if (event.data !== "") {
        const msg = JSON.parse(event.data);
        switch (msg.msgType) {
          case "LOT_UPDATE": dispatch(setUpdatedLots(msg));
        }
      }
    }
  }, [])

  return (
    <>
      <div className="container">
        {location.pathname === "/" ?
          isMobile() ?
            <img alt="header" className="inner-container" src={headerImgMobile}/>
            : <img alt="header" className="inner-container" src={headerImgHome}/>
          :
          isMobile() ?
            <img alt="header" className="inner-container" src={headerImgMobile}/>
            :
            <img alt="header" className="inner-container" src={headerImg}/>
        }

        {/*{isMobile() ? <HeaderMobile/> : <Header/>}*/}

        {children}

        {/*{isMobile() ? <FooterMobile/> : <Footer/>}*/}

      </div>
    </>
  )
}