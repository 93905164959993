import React from "react";
import {Button} from "@mui/material";
import {withStyles} from "@mui/styles";

export const LoginButton = withStyles(() => ({
  root: {
    color: "white",
    border: "2px solid #FF8359",
    "&:hover":{
      border: "2px solid #FF8140",
    },
    borderRadius: 16,
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    height: 34
  },
}))(Button);