import React, {useCallback, useEffect, useState} from "react";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {Link, useHistory} from "react-router-dom";
import {toEur} from "../../utils/tools";
import lotIcon1 from "../../images/lot-icon1.png";
import lotIcon2 from "../../images/win-icon.png";
import lotIcon3 from "../../images/fav-icon.png";
import bidIcon from "../../images/bid-icon.png";
import {BlueButton} from "../../components/BtbButton/BlueButton";
import {OrangeButton} from "../../components/BtbButton/OrangeButton";
import {isMobile} from "../../utils/checkDevice";
import lotIcon from "../../images/lot-icon.png";
import labelIcon from "../../images/label-icon.png";
import deliveryIcon from "../../images/lot-tabs/delivery.png";
import descriptionIcon from "../../images/lot-tabs/description.png";
import faqIcon from "../../images/lot-tabs/faq.png";
import historyIcon from "../../images/lot-tabs/history.png";
import {SmallTransparentButton} from "../../components/BtbButton/SmallTransparentButton";
import Countdown, {zeroPad} from "react-countdown";
import {GreenButton} from "../../components/BtbButton/GreenButton";
import {useSelector} from "react-redux";
import {Modal, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EuroIcon from "@mui/icons-material/Euro";

export const LotDetails = ({_lot = null}) => {
  const history = useHistory();
  const [lot, setLot] = useState(null);
  const id = new URLSearchParams(history.location.search).get('id');
  const [tabSelected, setTabSelected] = useState("tab_description");
  const [stateChanged, setStateChanged] = useState(false);
  const [bidHistory, setBidHistory] = useState();
  const [bidHistoryTable, setBidHistoryTable] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);
  const user = useSelector(state => state.base.user);
  const updatedLot = useSelector(state => state.base.updatedLots);

  const [openModalDeactivate, setOpenModalDeactivate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bidTo, setBidTo] = useState();
  const [bidFrom, setBidFrom] = useState();
  const [bids, setBids] = useState();

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleOpenModalDeactivate = () => {
    setOpenModalDeactivate(true);
  };
  const handleCloseModalDeactivate = () => {
    setOpenModalDeactivate(false);
  }

  const renderer = ({days, hours, minutes, seconds, completed}) => {
    return (
      <>
        {isMobile() ? <div>{zeroPad(days)}d : {zeroPad(hours)}h : {zeroPad(minutes)}m : {zeroPad(seconds)}s</div> :
          <>
            <div className="lot-details-timer-item">
              <div>{zeroPad(days)}</div>
              <div>Days</div>
            </div>
            <div className="lot-details-timer-item">
              <div className="lot-details-timer-colon">:</div>
            </div>
            <div className="lot-details-timer-item">
              <div>{zeroPad(hours)}</div>
              <div>Hours</div>
            </div>
            <div className="lot-details-timer-item">
              <div className="lot-details-timer-colon">:</div>
            </div>
            <div className="lot-details-timer-item">
              <div>{zeroPad(minutes)}</div>
              <div>Minutes</div>
            </div>
            <div className="lot-details-timer-item">
              <div className="lot-details-timer-colon">:</div>
            </div>
            <div className="lot-details-timer-item">
              <div>{zeroPad(seconds)}</div>
              <div>Seconds</div>
            </div>
          </>
        }
      </>
    );
  };

  const getLot = useCallback(async () => {
    try {
      const response = await client.getLotById(id);
      setLot(response);
    } catch (e) {
      notification.warning(e.message);
    }
  }, []);

  useEffect(() => {
    if (_lot === null) {
      getLot();
    } else {
      setLot(_lot);
    }
  }, []);

  useEffect(() => {
    if (lot && lot.id === updatedLot.id) {
      const l = lot;
      l.currentAmount = updatedLot.currentAmount;
      l.endDate = updatedLot.endDate;
      l.activeBidders = updatedLot.activeBidders;
      l.favorites = updatedLot.favorites;
      l.winnersPool = updatedLot.winnersPool;
      l.userLastBidder = user && updatedLot.lastBidderId === user.id;
      setLot(l);
      setStateChanged(true);
    }
  }, [updatedLot])

  const handleTabClick = (e) => {
    setTabSelected(e.target.id);
  }

  const placeBid = async () => {
    if (user === null) {
      history.push("/login");
    } else {
      try {
        const response = await client.placeBid(lot.id);
        setLot(response);
        setStateChanged(true);
      } catch (e) {
        notification.warning(e.message);
      }
    }
  }

  useEffect(() => {
    if (lot) {
      setLot(lot)
      setStateChanged(false);
    }
  }, [stateChanged]);

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }


  const [headerFields, setHeaderFields] = useState(!isMobile() ? [
    {name: 'bidder', value: 'Bidder', direction: 'asc', sorted: true},
    {name: 'date', value: 'Date', direction: 'asc', sorted: true},
    {name: 'time', value: 'Time', direction: 'asc', sorted: true},
    {name: 'bidPrice', value: 'Bid price', direction: 'asc', sorted: true},
  ] : [
    {name: 'bidder', value: 'Bidder', direction: 'asc', sorted: true},
    {name: 'time', value: 'Time', direction: 'asc', sorted: true},
    {name: 'bidPrice', value: 'Price', direction: 'asc', sorted: true},
  ]);

  useEffect(async () => {
    try {
      let response;
      if (tabSelected && tabSelected === "tab_history") {
        response = await client.getLotBidsHistory(lot.id, {
          page: currentPage,
          size: 10,
        })
        setBidHistory(response.content);
        setTotal(response.totalElements)
      }

    } catch (error) {
      notification.warning(error.message);
    }
  }, [tabSelected, currentPage]);

  useEffect(() => {
    bidHistory && setBidHistoryTable(
      <>
        <Table className="table" size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {bidHistory && bidHistory.map((e, index) => (
              <TableRow hover key={index}>
                <TableCell>{e.bidder}</TableCell>
                {!isMobile() && <TableCell>{e.date}</TableCell>}
                <TableCell>{e.time}</TableCell>
                <TableCell>{toEur(e.currentAmount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={10}
          rowsPerPageOptions={10}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          className="table-pagination"
        />
      </>
    )
  }, [bidHistory, total, currentPage]);

  const handleChangeBidTo = (e) => {
    setBidTo(+e.target.value)
  }
  const handleChangeBidFrom = (e) => {
    setBidFrom(+e.target.value)
  }
  const handleChangeBids = (e) => {
    setBids(+e.target.value)
  }

  const handleAutobidderSubmit = async (e) => {
    try {
      await client.createAutobidder({
        lotId: lot.id,
        bidTo: bidTo,
        bidFrom: bidFrom,
        bids: bids,
      });
      notification.success("Autobidder created");
      setLot({...lot, autobidderActive: true});
      handleCloseModal();
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const handleAutobidderDisable = async () => {
    try {
      await client.disableAutobidder(lot.id);
      notification.success("Autobidder disabled");
      setLot({...lot, autobidderActive: false});
      handleCloseModalDeactivate();
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const modalBody = (
    <div className="modal autobidder">
      <div className="modal-close-button"><CloseIcon onClick={handleCloseModal}
                                                     fontSize={isMobile() ? "small" : "medium"}/></div>
      <div className="modal-caption">Autobidder</div>
      <div className="modal-autobidder-balance">
        <div>Your balance:</div>
        <div>{user?.wallet.bidBalance}</div>
      </div>
      <form className="modal-autobidder-form" onSubmit={handleAutobidderSubmit}>
        <div>
          <div className="modal-autobidder-form-input-label">Bid from</div>
          <EuroIcon className="adornment-icon"/>
          <input id="bid-from" className="modal-autobidder-form-input" autocomplete="off"
                 onChange={handleChangeBidFrom}/>
        </div>
        <div>
          <div className="modal-autobidder-form-input-label">Bid to</div>
          <EuroIcon className="adornment-icon"/>
          <input id="bid-to" className="modal-autobidder-form-input" autocomplete="off" onChange={handleChangeBidTo}/>
        </div>
        <div>
          <div className="modal-autobidder-form-input-label">Bids to use</div>
          <input id="bids" className="modal-autobidder-form-input" autocomplete="off" onChange={handleChangeBids}/>
        </div>
      </form>
      <div className="modal-autobidder-form-button-container">
        <OrangeButton onClick={handleAutobidderSubmit}>Activate</OrangeButton>
      </div>

    </div>
  );

  const modalBodyDeactivate = (
    <div className="modal autobidder deactivate">
      <div className="modal-close-button"><CloseIcon onClick={handleCloseModalDeactivate}
                                                     fontSize={isMobile() ? "small" : "medium"}/></div>
      <div className="modal-autobidder-content-deactivate">
        <div>You have your autobidder enabled. To disable, click below!</div>
      </div>
      <div className="modal-autobidder-form-button-container">
        <OrangeButton onClick={handleAutobidderDisable}>Disable</OrangeButton>
      </div>
    </div>
  );

  return (
    <>
      <div className="content-relative">
        <div className="lot-details-container">
          <div className="lot-carousel-container">
            <div className="lot-carousel-main-image">
              <img alt={lot?.shortDescription} src={lot?.mainImage}/>
            </div>
            <div className="lot-carousel">

            </div>
          </div>

          <div className="lot-details">
            <div className="lot-details-left-container">
              <div className="lot-details-caption-container">
                <div className="lot-details-caption">{lot?.shortDescription}</div>
                <div className="lot-details-id">
                  Auction ID: {lot?.id}
                </div>
              </div>

              {isMobile() ?
                <>
                  <div className="lot-item-info-container">
                    <div>
                      <div><img alt="lot" src={lotIcon} className="lot-item-info-icon"/></div>
                      <div className="lot-item-price-container">
                        <div>Current Bid</div>
                        <div>{lot && toEur(lot.currentAmount)}</div>
                      </div>
                    </div>
                    <div>
                      <div><img alt="lot" src={labelIcon} className="lot-item-info-icon"/></div>
                      <div className="lot-item-price-container">
                        <div>Retail price</div>
                        <div>{lot && toEur(lot.retailPrice)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="lot-item-timer-container">
                    <div className="lot-item-timer">
                      {lot && <Countdown
                        key={new Date(lot.endDate + ".000Z")}
                        date={new Date(lot.endDate + ".000Z")}
                        renderer={renderer}
                      />}
                    </div>
                    <div className="lot-item-audobid-button"><SmallTransparentButton
                      onClick={handleOpenModal}>Autobid</SmallTransparentButton>
                    </div>
                  </div>
                  <div className="lot-item-submit-button">
                    {lot && lot.userLastBidder ?
                      <GreenButton>You’re the bidder</GreenButton>
                      :
                      <BlueButton onClick={placeBid}>Submit a Bid</BlueButton>
                    }
                  </div>
                </> :
                <>
                  <div className="lot-details-price-container">
                    <div>
                      <div>Current Bid</div>
                      <div>Retail price:</div>
                      <div>Bid Increment (EUR)</div>
                    </div>
                    <div>
                      <div>{lot && toEur(lot.currentAmount)}</div>
                      <div>{lot && toEur(lot.retailPrice)}</div>
                      <div>{lot && toEur(0.01)}</div>
                    </div>
                  </div>

                  <div className="lot-details-buttons-container">
                    <img alt="bid" src={bidIcon}/>
                    {lot &&
                    lot.userLastBidder ?
                      lot.userWinner ?
                        <OrangeButton>You won!</OrangeButton>
                        :
                        <GreenButton>You’re the bidder</GreenButton>
                      :
                      <BlueButton onClick={placeBid}>Submit a Bid</BlueButton>
                    }
                    {lot && lot.autobidderActive ?
                      <OrangeButton onClick={handleOpenModalDeactivate}>Disable autobidder</OrangeButton>
                      :
                      <OrangeButton onClick={handleOpenModal}>Activate autobidder</OrangeButton>
                    }

                  </div>
                </>
              }

            </div>

            {isMobile() ?
              <div className="lot-details-right-bottom-item">
                <img alt="lot" src={lotIcon2}/>
                <div>
                  <div>Winners pool <Link className="blue-link" style={{marginLeft: 20}} to={"/"}>Whats this?</Link>
                  </div>
                  <div>{lot && toEur(lot.winnersPool)}</div>
                </div>
              </div>
              :
              <div className="lot-details-right-container">
                <div>
                  <div className="lot-details-right-caption">This Auction Ends in:</div>
                  <div className="lot-details-timer">
                    {lot && <Countdown
                      key={new Date(lot.endDate + ".000Z")}
                      date={new Date(lot.endDate + ".000Z")}
                      renderer={renderer}
                    />}
                  </div>

                  <div className="lot-details-right-bottom-container">
                    <div className="lot-details-right-bottom-item">
                      <img alt="lot" src={lotIcon1}/>
                      <div>
                        <div>{lot && lot.activeBidders}</div>
                        <div>Active Bidders</div>
                      </div>
                    </div>
                    <div className="lot-details-right-bottom-item">
                      <img alt="lot" src={lotIcon2}/>
                      <div>
                        <div>{lot && toEur(lot.winnersPool)}</div>
                        <div>Winners pool <Link className="blue-link" style={{marginLeft: 20}} to={"/"}>Whats
                          this?</Link>
                        </div>
                      </div>
                    </div>
                    <div className="lot-details-right-bottom-item">
                      <img alt="lot" src={lotIcon3}/>
                      <div>
                        <div>{lot?.favoritesCount}</div>
                        <div>Favorites</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lot-details-right-link-container">
                  <Link className="blue-link" to={"/"}>View Auction Terms of Service</Link>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <div className="lot-details-tabs-container">
        <div className="lot-details-tabs">
          <div
            className={tabSelected === "tab_description" ? "lot-details-tab lot-details-tab-active" : "lot-details-tab"}>
            <img alt="description" src={descriptionIcon}/>
            {!isMobile() && <div>Description</div>}
            <div id="tab_description" className="lot-details-tab-absolute" onClick={handleTabClick}/>
          </div>
          <div
            className={tabSelected === "tab_delivery" ? "lot-details-tab lot-details-tab-active" : "lot-details-tab"}>
            <img alt="delivery" src={deliveryIcon}/>
            {!isMobile() && <div>Delivery Options</div>}
            <div id="tab_delivery" className="lot-details-tab-absolute" onClick={handleTabClick}/>
          </div>
          <div className={tabSelected === "tab_history" ? "lot-details-tab lot-details-tab-active" : "lot-details-tab"}>
            <img alt="bid" src={historyIcon}/>
            {!isMobile() && <div>Bid History</div>}
            <div id="tab_history" className="lot-details-tab-absolute" onClick={handleTabClick}/>
          </div>
          <div
            className={tabSelected === "tab_questions" ? "lot-details-tab lot-details-tab-active" : "lot-details-tab"}>
            <img alt="faq" src={faqIcon}/>
            {!isMobile() && <div>Questions</div>}
            <div id="tab_questions" className="lot-details-tab-absolute" onClick={handleTabClick}/>
          </div>
        </div>
      </div>

      <div className="content-relative-inner">
        {tabSelected === "tab_description" && lot && <div dangerouslySetInnerHTML={{__html: lot.longDescription}}/>}
        {tabSelected === "tab_delivery" && lot && <div dangerouslySetInnerHTML={{__html: lot.deliveryOptions}}/>}
        {tabSelected === "tab_history" && bidHistoryTable && bidHistoryTable}
        {tabSelected === "tab_questions" && lot && ""}
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>

      <Modal
        open={openModalDeactivate}
        onClose={handleCloseModalDeactivate}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyDeactivate}
      </Modal>
    </>
  )
}