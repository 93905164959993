import React, {useEffect, useState} from "react";
import lotIcon from '../../images/lot-icon.png';
import labelIcon from '../../images/label-icon.png';
import {toEur} from "../../utils/tools";
import {SmallTransparentButton} from "../BtbButton/SmallTransparentButton";
import {BlueButton} from "../BtbButton/BlueButton";
import {useHistory} from "react-router-dom";
import Countdown, {zeroPad} from "react-countdown";
import {notification} from "../Notification";
import {client} from "../../services/client";
import {GreenButton} from "../BtbButton/GreenButton";
import {useSelector} from "react-redux";
import favDisabledIcon from "../../images/fav-disabled.png";
import favEnabledIcon from "../../images/fav-icon.png";
import {Modal} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EuroIcon from '@mui/icons-material/Euro';
import {OrangeButton} from "../BtbButton/OrangeButton";
import {isMobile} from "../../utils/checkDevice";

export const LotItem = ({lot}) => {
  const history = useHistory();
  const [lotState, setLotState] = useState(null);
  const [stateChanged, setStateChanged] = useState(false);
  const user = useSelector(state => state.base.user);
  const [openModalDeactivate, setOpenModalDeactivate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bidTo, setBidTo] = useState();
  const [bidFrom, setBidFrom] = useState();
  const [bids, setBids] = useState();
  const updatedLot = useSelector(state => state.base.updatedLots);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModalDeactivate = () => {
    setOpenModalDeactivate(true);
  };
  const handleCloseModalDeactivate = () => {
    setOpenModalDeactivate(false);
  }

  useEffect(() => {
    if (lot) {
      setLotState(lot);
      setStateChanged(false);
    }
  }, [])

  useEffect(() => {
    if (lotState && lotState.id === updatedLot.id) {
      const l = lotState;
      l.currentAmount = updatedLot.currentAmount;
      l.endDate = updatedLot.endDate;
      l.activeBidders = updatedLot.activeBidders;
      l.favorites = updatedLot.favorites;
      l.winnersPool = updatedLot.winnersPool;
      l.userLastBidder = user && updatedLot.lastBidderId === user.id;
      setLotState(l);
      setStateChanged(true);
    }
  }, [updatedLot])

  const renderer = ({days, hours, minutes, seconds, completed}) => {
    return (
      <div>{zeroPad(days)}d : {zeroPad(hours)}h : {zeroPad(minutes)}m : {zeroPad(seconds)}s</div>
    );
  };

  const placeBid = async () => {
    if (user === null) {
      history.push("/login");
    } else {
      try {
        const response = await client.placeBid(lot.id);
        setLotState(response);
      } catch (e) {
        notification.warning(e.message);
      }
    }
  }

  useEffect(() => {
    if (lotState) {
      setLotState(lotState)
      setStateChanged(false);
    }
  }, [stateChanged]);

  const toggleFavorite = async () => {
    if (user === null) {
      history.push("/login");
    } else {
      try {
        await client.toggleFavorite(lot.id);
        let _lot = {...lotState};
        _lot.favorite = !_lot.favorite;
        setLotState(_lot);
      } catch (e) {
        notification.warning(e.message);
      }
    }
  }

  const handleChangeBidTo = (e) => {
    setBidTo(+e.target.value)
  }
  const handleChangeBidFrom = (e) => {
    setBidFrom(+e.target.value)
  }
  const handleChangeBids = (e) => {
    setBids(+e.target.value)
  }

  const handleAutobidderSubmit = async (e) => {
    try {
      await client.createAutobidder({
        lotId: lotState.id,
        bidTo: bidTo,
        bidFrom: bidFrom,
        bids: bids,
      });
      notification.success("Autobidder created");
      setLotState({...lotState, autobidderActive: true});
      handleCloseModal();
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const handleAutobidderDisable = async () => {
    try {
      await client.disableAutobidder(lot.id);
      notification.success("Autobidder disabled");
      setLotState({...lotState, autobidderActive: false});
      handleCloseModalDeactivate();
    } catch (e) {
      notification.warning(e.message);
    }
  }

  const modalBodyDeactivate = (
    <div className="modal autobidder deactivate">
      <div className="modal-close-button"><CloseIcon onClick={handleCloseModalDeactivate}
                                                     fontSize={isMobile() ? "small" : "medium"}/></div>
      <div className="modal-autobidder-content-deactivate">
        <div>You have your autobidder enabled. To disable, click below!</div>
      </div>
      <div className="modal-autobidder-form-button-container">
        <OrangeButton onClick={handleAutobidderDisable}>Disable</OrangeButton>
      </div>
    </div>
  );

  const modalBody = (
    <div className="modal autobidder">
      <div className="modal-close-button"><CloseIcon onClick={handleCloseModal}
                                                     fontSize={isMobile() ? "small" : "medium"}/></div>
      <div className="modal-caption">Autobidder</div>
      <div className="modal-autobidder-balance">
        <div>Your balance:</div>
        <div>{user?.wallet.bidBalance}</div>
      </div>
      <form className="modal-autobidder-form" onSubmit={handleAutobidderSubmit}>
        <div>
          <div className="modal-autobidder-form-input-label">Bid from</div>
          <EuroIcon className="adornment-icon"/>
          <input id="bid-from" className="modal-autobidder-form-input" autocomplete="off"
                 onChange={handleChangeBidFrom}/>
        </div>
        <div>
          <div className="modal-autobidder-form-input-label">Bid to</div>
          <EuroIcon className="adornment-icon"/>
          <input id="bid-to" className="modal-autobidder-form-input" autocomplete="off" onChange={handleChangeBidTo}/>
        </div>
        <div>
          <div className="modal-autobidder-form-input-label">Bids to use</div>
          <input id="bids" className="modal-autobidder-form-input" autocomplete="off" onChange={handleChangeBids}/>
        </div>
      </form>
      <div className="modal-autobidder-form-button-container">
        <OrangeButton onClick={handleAutobidderSubmit}>Activate</OrangeButton>
      </div>

    </div>
  );

  return (
    <>
      <div className="lot-item">
        <div>
          <div className="lot-item-image-container">
            {lot?.mainImage && <img alt={lot?.shortDescription} src={lot?.mainImage}/>}
            <div className="fav-icon-container" onClick={toggleFavorite}>
              <img src={lotState?.favorite ? favEnabledIcon : favDisabledIcon}/>
            </div>
          </div>
          <div className="lot-item-short-description" onClick={() => history.push("lot?id=" + lot?.id)}>
            {lot?.shortDescription}
          </div>
        </div>
        <div className="lot-item-info-container">
          <div>
            <div><img alt="lot" src={lotIcon} className="lot-item-info-icon"/></div>
            <div className="lot-item-price-container">
              <div>Current Bid</div>
              <div>{lotState && toEur(lotState.currentAmount)}</div>
            </div>
          </div>
          <div>
            <div><img alt="lot" src={labelIcon} className="lot-item-info-icon"/></div>
            <div className="lot-item-price-container">
              <div>Retail price</div>
              <div>{lot && toEur(lot.retailPrice)}</div>
            </div>
          </div>
        </div>
        <div className="lot-item-timer-container">
          <div className="lot-item-timer">
            {lotState && <Countdown
              key={new Date(lotState.endDate + ".000Z")}
              date={new Date(lotState.endDate + ".000Z")}
              renderer={renderer}
            />}
          </div>
          <div className="lot-item-audobid-button">
            {lotState && lotState.autobidderActive ?
              <SmallTransparentButton onClick={handleOpenModalDeactivate}>Autobid</SmallTransparentButton>
              :
              <SmallTransparentButton onClick={handleOpenModal}>Autobid</SmallTransparentButton>
            }
          </div>
        </div>
        <div className="lot-item-submit-button">
          {lotState && lotState.userLastBidder ?
            <GreenButton>You’re the bidder</GreenButton>
            :
            <BlueButton onClick={placeBid}>Submit a Bid</BlueButton>
          }
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>


      <Modal
        open={openModalDeactivate}
        onClose={handleCloseModalDeactivate}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBodyDeactivate}
      </Modal>
    </>
  )
}