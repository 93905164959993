import React, {useEffect, useState} from "react";
import {notification} from "../../components/Notification";
import {useHistory} from "react-router-dom";
import {client} from "../../services/client";
import {InputAdornment, TextField} from "@mui/material";
import {LockOutlined} from "@mui/icons-material";
import {OrangeButton} from "../../components/BtbButton/OrangeButton";

export const ConfirmPassword = () => {
  const history = useHistory();
  const [confirmationCode, setConfirmationCode] = useState();

  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get('confirmationCode');
    if (!code) {
      notification.warning("Wrong code");
      history.push("/login");
    }
    setConfirmationCode(code);
  });

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      await client.confirmResetPassword({
        password: e.target.password.value,
        confirmationCode: confirmationCode
      });
      notification.success("Password changed. You can login now.");
      history.push("/login")
    } catch (error) {
      notification.warning(error.message);
    }
  }

  return(
    <div className="content-relative login-container">
      <form className="login-form" onSubmit={handleConfirm}>
        <div>
          <TextField
            id="password"
            type="password"
            label="New password"
            autoComplete='off'
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined/>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="signup-form-button">
          <OrangeButton type="submit">Confirm</OrangeButton>
        </div>
      </form>
    </div>
  )
}