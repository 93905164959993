import React from 'react';
import {Route, Switch} from "react-router";
import {Redirect} from "react-router-dom";
import {MainLayout} from "./components/Layouts/MainLayout/MainLayout";
import {Home} from "./pages/home/Home";
import {Signup} from "./pages/auth/Signup";
import {Login} from "./pages/auth/Login";
import {LotList} from "./pages/lots/LotList";
import {LotDetails} from "./pages/lots/LotDetails";
import ScrollToTop from "./ScrollToTop";
import {Faq} from "./pages/faq/Faq";
import {MyBids} from "./pages/account/MyBids";
import {Dashboard} from "./pages/account/Dashboard";
import {PersonalProfile} from "./pages/account/PersonalProfile";
import {WinningBids} from "./pages/account/WinningBids";
import {MyAlerts} from "./pages/account/MyAlerts";
import {MyFavorites} from "./pages/account/MyFavorites";
import {InviteAndEarn} from "./pages/account/InviteAndEarn";
import {PromotionsAndRewards} from "./pages/account/PromotionsAndRewards";
import {BidPacksOrder} from "./pages/orders/BidPacksOrder";
import {OrderStatus} from "./pages/orders/OrderStatus";
import {ConfirmPassword} from "./pages/auth/ConfirmPassword";
import {LotsOrder} from "./pages/orders/LotsOrder";
import {PaypalReturnUrl} from "./pages/orders/PaypalReturnUrl";


export const useRoutes = (isAuth) => {
  return (
    <MainLayout>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact={true}>
          <Home/>
        </Route>
        <Route path="/signup">
          <Signup/>
        </Route>
        <Route path="/login">
          <Login/>
        </Route>
        <Route path="/password-reset">
          <ConfirmPassword/>
        </Route>
        <Route path="/categories">
          <Home/>
        </Route>
        <Route path="/lots">
          <LotList/>
        </Route>
        <Route path="/lot">
          <LotDetails/>
        </Route>
        <Route path="/faq">
          <Faq/>
        </Route>

        <Route path="/account/dashboard" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <Dashboard />
          )
        )}/>

        <Route path="/account/profile" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <PersonalProfile/>
          )
        )}/>

        <Route path="/account/bids" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <MyBids/>
          )
        )}/>

        <Route path="/account/win" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <WinningBids/>
          )
        )}/>

        <Route path="/account/alerts" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <MyAlerts/>
          )
        )}/>

        <Route path="/account/favorites" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <MyFavorites/>
          )
        )}/>

        <Route path="/account/invite" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <InviteAndEarn/>
          )
        )}/>

        <Route path="/account/rewards" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <PromotionsAndRewards/>
          )
        )}/>


        <Route path="/orders/bids/packs" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <BidPacksOrder/>
          )
        )}/>

        <Route path="/orders/lots" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <LotsOrder/>
          )
        )}/>

        <Route path="/orders/status" render={() => (
          !isAuth ? (
            <Redirect to="/login"/>
          ) : (
            <OrderStatus/>
          )
        )}/>

        <Route path="/paypal/return">
          <PaypalReturnUrl/>
        </Route>



        <Route path="/">
          <Redirect to={'/'}/>
        </Route>
      </Switch>
    </MainLayout>
  )
}