import React, {useEffect, useState} from "react";
import {AccountMenu} from "../../components/AccountMenu/AccountMenu";
import {isMobile} from "../../utils/checkDevice";
import "./Account.css";
import {BidBox} from "../../components/BidBox/BidBox";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from "@mui/material";
import {client} from "../../services/client";
import {notification} from "../../components/Notification";
import {toEur} from "../../utils/tools";
import {useHistory} from "react-router-dom";

export const MyBids = () => {
  const [tabValue, setTabValue] = useState("tab1");
  const [tabContent, setTabContent] = useState();
  const [currentPage, setCurrentPage] = useState(0)
  const [total, setTotal] = useState(1);
  const [bids, setBids] = useState();
  const history = useHistory();

  const [headerFields, setHeaderFields] = useState(!isMobile() ? [
    {name: 'item', value: 'Item', direction: 'asc', sorted: true},
    {name: 'totalBids', value: 'Total bids', direction: 'asc', sorted: true},
    {name: 'valueBids', value: 'Value bids', direction: 'asc', sorted: true},
    {name: 'currentBid', value: 'Current bid', direction: 'asc', sorted: true},
    {name: 'highBidder', value: 'High bidder', direction: 'asc', sorted: true},
  ] : [
    {name: 'item', value: 'Item', direction: 'asc', sorted: true},
    {name: 'currentBid', value: 'Current bid', direction: 'asc', sorted: true},
    {name: 'highBidder', value: 'High bidder', direction: 'asc', sorted: true},
  ])

  useEffect(async () => {
    try {
      let response;
      if (tabValue && tabValue === "tab1") {
        console.log(tabValue);
        response = await client.getMyBidsActive({
          page: currentPage,
          size: 10,
        })
      } else if (tabValue && tabValue === "tab2") {
        response = await client.getMyBidsHistory({
          page: currentPage,
          size: 10,
        })
      }
      setBids(response.content);
      setTotal(response.totalElements)

    } catch (error) {
      notification.warning(error.message);
    }
  }, [currentPage, tabValue]);

  useEffect(() => {
    bids && setTabContent(
      <>
        <Table className="table" size="small">
          <TableHead>
            <TableRow>
              {headerFields.map(f =>
                <TableCell key={f.name}>{f.value}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {bids && bids.map((e, index) => (
              <TableRow hover key={index} onClick={() => history.push("/lot?id=" + e.id)}>
                <TableCell>{e.shortDescription}</TableCell>
                {!isMobile() &&<TableCell>{e.youSpentBids}</TableCell>}
                {!isMobile() &&<TableCell>{toEur(e.youSpentValue)}</TableCell>}
                <TableCell>{toEur(e.currentAmount)}</TableCell>
                <TableCell>{e.highBidder}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={10}
          rowsPerPageOptions={10}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onPageChange={onChangePage}
          className="table-pagination"
        />
      </>
    )
  }, [bids, total, currentPage]);

  const handleChangeTab = (e) => {
    console.log(e.target.id)
    setTabValue(e.target.id);
  };

  const onChangePage = (_, newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <div className="content-relative">
      <div className="account-container">
        {!isMobile() && <AccountMenu tab={"bids"}/>}
        <div>
          <BidBox title="My Bids"/>

          <div className="dashboard-box">
            <div className="caption">My Bids</div>
            <div className="tab-container">
              <div className="tab-buttons-container">
                <div id="tab1" className={tabValue && tabValue === "tab1" ? "tab-button active" : "tab-button"}
                     onClick={handleChangeTab}>Active
                </div>
                <div id="tab2" className={tabValue && tabValue === "tab2" ? "tab-button active" : "tab-button"}
                     onClick={handleChangeTab}>History
                </div>
              </div>
            </div>
            <div className="tab-content">{tabContent && tabContent}</div>
          </div>
        </div>
      </div>
    </div>
  )
}